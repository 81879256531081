.privacy-policy {
    color: white;

    .container-xxl {
        .content {
            padding: 60px 140px 260px;

            a {
                color: white;
                &:hover {
                    color: #00EAC7;
                }
            }

            h3 {
                margin-bottom: 25px!important;
                color: #00EAC7 !important;
            }

            .list {
                list-style: circle;
                color:red;
            }
        }
    }
}


@media screen and (max-width: 992px) {
    .privacy-policy {
        color: white;

        .container-xxl {
            .content {
                padding: 60px 0 100px;
            }
        }
    }
}
