/*-------------------------*/
/*          Header         */
/*-------------------------*/
.header {
    width: 100%;
    background-color: #FF6F67;
    height: 100vh;
    overflow: hidden;

    .vector {
        position: absolute;
        top: 0;
        right: 10%;

        img {
            height: 100vh;
        }
    }

    .row-title {
        padding-top: 250px;
        h1 {
            font-size: 135px;
            color: #231F20;
            line-height: 117px;
        }

        h2 {
            font-size: 55px;
            color: #231F20;
            padding-top: 85px;
            line-height: 48px;
        }
        
        .second-img {
            height: 220px;
        }
    }

    .row-timer {
        padding-top: 20px;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        z-index: 5;

        h3 {
            color: black!important;
        }

        .timer {
            font-weight: 700;
            font-size: 40px;
            line-height: 49px;
        }
    }
}




// LAPTOP
@media screen and (max-width: 1448px) {
    .header {
        height: 82vh;

        .vector {
            top: 0;
            right: -13%;

            img {
                height: 100vh;
            }
        }

        .row-title {
            padding-top: 80px!important;

            .main-img {
                height: 365px;
            }
        }

        .row-timer {
            padding-top: 20px;
        }
    }
}



@media screen and (max-width: 992px) {
    .header {
        .row-title {
            padding-top: 50px;

            .main-img {
                height: 280px
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .header {
        height: auto;

        hr {
            display: none;
        }

        .vector {
            top: 200px;

            img {
                height: 50vh;
            }
        }

        .row-title {
            padding-top: 50px;
            padding-bottom: 30px;

            .main-img {
                margin-top: 80px;
            }

            .second-img {
                height: 180px;
            }
        }

        .row-timer {
            padding-bottom: 30px;

            h3 {
                font-size: 18px;
            }
        }
    }
}


@media screen and (max-width: 430px) {
    .header {
        .vector {
            top: 200px;

            img {
                height: 50vh;
            }
        }

        .row-title {
            .main-img {
                height: 250px
            }
        }

        .row-timer {
            padding-bottom: 30px;
        }
    }
}


@media screen and (max-width: 350px) {
    .header {
        .vector {
            top: 200px;

            img {
                height: 50vh;
            }
        }

        .row-title {
            .main-img {
                height: 230px
            }
        }
    }
}


