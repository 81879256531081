#about {
    width: 100%;
    color: #FFFFFF;

    .container-xxl {
        padding-top: 140px;
        padding-bottom: 160px;
        position: relative;

        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 39px;
            text-transform: uppercase;
            line-height: 37px;
        }
    }
}

@media screen and (max-width: 1200px) {
    #about {
        .container-xxl {
            padding-top: 100px;

            .btn-read-more {
                position: absolute;
                top: 82%;
                left: 20px;
            }

            .text {
                margin-bottom: 60px;
                font-weight: 400;
                font-size: 30px;
                line-height: 86.3%;
            }

            .title-sm {
                position: relative;
                top: -7px;
            }

            .fs-14 {
                padding-top: 60px;
                display: block;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    #about {
        .container-xxl {
            .text {
                font-size: 24px;
                line-height: 86.3%;
            }
        }
    }
}




// PAGE ABOUT

.about {
    padding: 60px 0 380px;
    color: white;

    .container-xxl {
        padding: 0 140px !important;

        .title-sm {
            padding-bottom: 40px;
            display: block;
        }

        hr {
            width: 40%;
        }

        .text-green {
            font-weight: 400;
            font-size: 40px;
            line-height: 94.8%;
            color: #5DE7C9;
        }

        .fs-16 {
            font-weight: 400;
        }
    }
}

@media screen and (max-width: 1200px) {
    .about {
        padding: 60px 0 230px;

        .container-xxl {

            img {
                margin-bottom: 50px;
            }

            .text-green {
                font-size: 34px;
                line-height: 38px;
            }
        }
    }


}



@media screen and (max-width: 992px) {
    .about .container-xxl {
        padding: 0 20px!important;
    }
}
