#categories {
    .container-xxl {
        padding-bottom: 230px;

        .all-categories {
            position: relative;

            .block-category {

                img {
                    position: absolute;
                    right: 0;
                    top: 0;
                    transition: all 0.8s ease-in-out;
                    opacity: 0;
                }

                .name {
                    font-weight: 400;
                    font-size: 60px;
                    line-height: 72px;
                    color: white;
                    text-transform: uppercase;
                    cursor: pointer;
                    transition: all 0.4s;
                }

                .text-category {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 21px;
                    height: 0;
                    opacity: 0;
                    transition: all 0.4s;
                    width: 70%;
                    display: block;
                    color: white;
                    pointer-events: none;
                }
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    #categories .container-xxl {
        h3 {
            padding-top: 220px;
        }

        .all-categories {
            .block-category {
                .name {
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    #categories .container-xxl {
        h3 {
            padding-top: 140px;
            font-size: 16px;
            line-height: 19px;
        }

        .all-categories {
            .block-category {
                margin-bottom: 10px;

                .name {
                    font-size: 28px;
                    line-height: 94.3%;
                }

                img {
                    display: none;
                }

                .text-category {
                    width: 100%;
                }
            }
        }
    }
}
