.navbar {
    background-color: #FF6F67;
    --bs-navbar-color: none;

    .container-xxl {
        z-index: 1;

        .navbar-toggler {
            border: none;

            &:focus,
            &:active {
                outline: none;
                box-shadow: none;
            }

            .navbar-toggler-icon:focus {
                outline: none;
                box-shadow: none;
            }
        }

        li {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;

            a {
                display: inline;

                &:hover {
                    color: #FFFFFF;
                }
            }
        }
    }
}

.black-bg {
    background-color: #121212;
}

.black-bg .nav-link {
    color: white;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0!important;
}

.nav-btn {
    width: 30px;
}

@media screen and (max-width: 991px) {
    .navbar {
        --bs-navbar-color: none;

        .container-xxl {
            ul {
                height: 100vh;
                margin-top: 100px;

                li {
                    font-weight: 500;
                    font-size: 38px;
                    line-height: 44px;
                    text-transform: uppercase;

                    a {
                        color: #121212;

                        &:hover {
                            color: #FFFFFF;
                        }
                    }
                }
            }


        }
    }
}
