.rules {
    padding-top: 65px;
    padding-bottom: 300px;

    .container-xxl {
        padding: 0 140px!important;

        .accordion {
            --bs-accordion-bg: none;

            .accordion-button {
                color: white;

                &:after {
                    display: none;
                }
            }

            .accordion-body {
                span, p {
                    color: white;
                }
            }

            .accordion-button:not(.collapsed) {
                background: none;
            }

            .accordion-button:focus {
                box-shadow: none;
            }

            .accordion-body a {
                color: white;
                text-decoration: none;
            }
        }

        .icon-arrow {
            top: 48px;
            left: 12px;
            transition: all 0.4s ease-in-out;

            &:hover {
                transform: scale(1.2);
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .rules .container-xxl {
        padding: 0 100px!important;

        .icon-arrow {
            display: none;
        }
    }
}


@media screen and (max-width: 768px) {
    .rules .container-xxl {
        padding: 0 60px!important;
    }
}


@media screen and (max-width: 576px) {
    .rules .container-xxl {
        padding: 0 20px!important;

        .accordion-button {
            font-size: 18px!important;
        }
    }
}
