#sponsors {
    padding-bottom: 350px;

    .container-xxl {

        .content {
            padding: 100px 20px;

            h3 {
                margin-bottom: 100px!important;
            }

            .row {
                &.line-1 {
                    margin-bottom: 100px;
                }

                span {
                    font-size: 14px;
                    color: #625b5b;
                }

                .col-md-4 {
                    margin-top: 0;

                    img {
                        display: block;
                        width: 200px;

                        &.logo-ville {
                            width: 150px;
                        }
                    }

                    span {
                        display: block;
                        margin-bottom: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    #sponsors {
        padding-bottom: 200px;

        .container-xxl {
            h3 {
                font-size: 16px;
                line-height: 19px;
            }

            .content {
                .row {
                    padding: 0;
                }
            }
        }


    }
}


@media screen and (max-width: 768px) {
    #sponsors {
        padding-bottom: 0;

        .container-xxl .content {
            padding: 60px 20px;

            .row {
                .col-md-4 {
                    img {
                        margin: 0 auto;
                    }
                }

                &.line-1 {
                    margin-bottom: 0;
                }
            }
        }
    }
}


@media screen and (max-width: 350px) {
    #sponsors {
        .container-xxl .content {
            .row {
                span {
                    font-size: 12px;
                }
            }
        }
    }
}
