.edition {
    .container-xxl {
        padding: 60px 0 260px;

        .tabs {
            padding: 0 140px;

            .tab-pane {
                color: white;
            }

            hr {
                background: white;
                height: 2px;
                opacity: 1;
            }

            .block-laureat {
                margin: 60px 0;

                h3 {
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 48px;
                    margin-bottom: 25px!important;
                }

                ul > li {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .swiper {
                margin-top: 200px;

                .swiper-wrapper {
                    .swiper-slide {
                        height: 350px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
            .buttons {
                margin-top: 30px;
                display: flex;
                justify-content: center;

                .button {
                    display: flex;
                    justify-content: center;
                    padding: 20px;
                    border-radius: 50%;
                    border: 2px solid white;
                    width: 45px;
                    height: 45px;

                    .swiper-button-next,
                    .swiper-button-prev {
                        position: static!important;
                        color: white!important;

                        &:after {
                            font-size: 20px;
                        }
                    }
                }

                .btn-left {
                    margin-right: 30px;
                }
            }
        }
    }
}


@media screen and (max-width: 850px) {
    .edition {
        .container-xxl {
            .tabs {
                padding: 0 50px;
            }
        }
    }
}


@media screen and (max-width: 576px) {
    .edition {
        .container-xxl {
            .tabs {
                padding: 0;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .edition {
        .container-xxl {
            .tabs {
                padding: 0;

                .block-laureat h3 {
                    font-size: 30px;
                    line-height: 28px;;
                }
            }
        }
    }
}