.card-custom {
    width: 100%;
    height: 385px;
    cursor: pointer;
    transition: all 0.8s ease-in-out;

    &:hover .card-img img {
        -webkit-filter: none;
        filter: none;
        transform: scale(1.1);
    }

    .card-img {
        height: 335px;
        overflow: hidden;

        img {
            height: 100%;
            object-fit: cover;
            width: 100%;
            -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
            transition: all 0.4s ease-in-out;
        }
    }

    .card-logo {
        background-color: #5DE7C9;
        width: 82px;
        height: 82px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
    }

    .card-body span {
        padding: 12px 0;
        color: white;
        display: block;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
    }
}


