footer {
    background-color: #00EAC7;

    .container-xxl {
        padding: 20px;

        .logo-footer img {
            height: 400px;
        }

        a {
            color: black;
            transition: all 0.8s ease-in-out;
            &:hover {
                color: #DE66C8;
            }
        }

        .block-right-footer {
            /*
            text-align: end;
            */
            padding-left: 570px;

            .menu {
                margin-bottom: 45px;

                span {
                    display: block;
                    cursor: pointer;
                    transition: all 0.8s ease-in-out;
                }

                a {
                    &:hover span {
                        transform: translateX(50px);
                    }
                }
            }

            .contact {
                a {
                    transition: all 0.4s ease-in-out;

                    span {
                        display: block;
                        cursor: pointer;
                    }
                }
            }

            .social-networks i {
                font-size: 30px;
            }
        }

        .signature {
            position: absolute;
            right: 0;
        }

        .mail.fs-40 {
            text-align: end;
            margin-top: 6px;
        }

        .copyright {
            position: relative;
            display: flex;
        }
    }
}

@media screen and (max-width: 1500px) {
    footer {
        .container-xxl {
            .block-right-footer {
                .menu {
                    a {
                        &:hover span {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    footer {
        .container-xxl {
            .logo-footer img {
                height: 325px;
            }

            .block-right-footer {
                padding-left: 0;
                text-align: end;

                .menu {

                    a {
                        &:hover span {
                            transform: translateX(0);
                        }
                    }
                }
            }

            .fs-40 {
                font-size: 30px;
            }

            .copyright {
                text-align: center;
                font-size: 14px;
            }

            .signature {
                margin-top: 20px;
                position: static;
                display: block;
                text-align: center;
                font-size: 14px;
            }
        }
    }
}


@media screen and (max-width: 768px) {
    footer {
        .container-xxl {
            .logo-footer img {
                height: 325px;
            }

            .block-right-footer {
                padding-left: 0;
                text-align: start;
                margin-top: 30px;
            }

            .fs-40 {
                font-size: 25px;
            }

            .mail.fs-40 {
                text-align: start;
            }
        }
    }
}


@media screen and (max-width: 576px) {
    footer {
        .container-xxl {
            .logo-footer img {
                height: 225px;
            }

            .fs-40 {
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    footer {
        .container-xxl {
            .logo-footer img {
                height: 225px;
            }

            .fs-40 {
                font-size: 16px;
            }
        }
    }
}
