#jury {
    width: 100%;
    padding-bottom: 300px;
}

@media screen and (max-width: 1200px) {
    #jury {
        padding-bottom: 200px;

        h3 {
            font-size: 16px;
            line-height: 19px;
        }
    }
}
