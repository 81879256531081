/*-------------------------*/
/*          General        */
/*-------------------------*/
body {
    font-family: 'Helvetica', 'Arial', sans-serif !important;
    background-color: #121212;
}

a {
    text-decoration: none;

    &:hover {
        color: black;
    }
}

.container-xxl {
    padding-left: 20px !important;
    padding-right: 20px !important;
}


/*-------------------------*/
/*          Button         */
/*-------------------------*/
.btn-black,
.btn-white {
    padding: 8px 50px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: inline-block;
}

.btn-white {
    color: black;
    background-color: #FFFFFF;
}

.btn-black {
    color: white;
    background-color: #000000;
}

.btn-black:hover {
    color: black;
}

.btn-white::after,
.btn-black::after {
    content: "";
    position: absolute;
    z-index: -1;
    padding: 0.85em 0.75em;
    display: block;
    transition: all 0.8s;
}

.btn-white::after {
    background: #00EAC7;
}

.btn-black::after {
    background: white;
}

.btn-white:hover::after,
.btn-black:hover::after {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: all 0.8s;
}

.btn-white::after,
.btn-black::after {
    top: 0;
    bottom: 0;
    left: -100%;
    right: 100%;
}

.btn-white.active {
    background: #00EAC7;
}


/*-------------------------*/
/*        Z -Index         */
/*-------------------------*/
.z-1 {
    z-index: 1;
}

/*-------------------------*/
/*          Margin         */
/*-------------------------*/
.mt-30 {
    margin-top: 50px;
}

.mt-40 {
    margin-top: 40px;
}

.mt-50 {
    margin-top: 50px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-60 {
    margin-bottom: 60px!important;
}

.mb-100 {
    margin-bottom: 100px;
}


/*-------------------------*/
/*         Padding         */
/*-------------------------*/
.pb-50 {
    padding-bottom: 50px;
}

.pb-60 {
    padding-bottom: 60px;
}

.pt-50 {
    padding-top: 50px;
}

.pt-40 {
    padding-top: 40px;
}

.py-20 {
    padding: 20px 0;
}

/*-------------------------*/
/*           Text          */
/*-------------------------*/
.fs-12 {
    font-size: 12px;
    font-weight: 300;
}

.fs-14 {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.fs-16 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
}

.fs-24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
}

.fs-40 {
    font-weight: 400;
    font-size: 40px;
    line-height: 36px;
}

.bold {
    font-weight: 700;
}


/*@media screen and (max-width: 1500px) {
    .fs-40 {
        font-weight: 400;
        font-size: 30px;
        line-height: 92.3%;
    }
}

@media screen and (max-width: 1150px) {
    .fs-40 {
        font-weight: 400;
        font-size: 22px;
        line-height: 92.3%;
    }
}*/

/*-------------------------*/
/*          HEADER         */
/*-------------------------*/
h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: white !important;
}


/*-------------------------*/
/*        CONTAINER        */
/*-------------------------*/
@media screen and (min-width: 1400px) {
    .container-xxl {
        max-width: 1440px;
    }
}


/*-------------------------*/
/*          TITLE          */
/*-------------------------*/
.title-sm {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
    text-transform: uppercase;
}

.title-xl {
    font-weight: 400;
    font-size: 60px;
    line-height: 86.3%;
}

