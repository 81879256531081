.jury {
    padding: 60px 0 380px;

    .container-xxl {
        padding: 0 140px!important;

        /*    margin-left: -100%;
            opacity: 0;*/

        .icon-arrow {
            transition: all 0.4s ease-in-out;
            left: 16px;
            top: 74px;

            &:hover {
                transform: scale(1.2);
            }
        }

        .block-img img {
            width: 100%;
            height: 550px;
            object-fit: contain;
        }

        .social-networks a {
            transition: all 0.4s ease-in-out;

            &:hover {
                transform: scale(1.2);
            }

            img {
                height: 30px;
            }
        }


        .card-logo {
            background-color: #5DE7C9;
            width: 82px;
            height: 82px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 250px;
            right: 0;
            z-index: 5;
        }
    }
}


@media screen and (max-width: 1450px) {
    .jury {
        padding: 60px 0 250px;

        .container-xxl {
            .card-logo {
                margin-right: 20px;
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .jury {
        padding: 60px 0 250px;

        .container-xxl {
            .card-logo {
                display: none;
            }

            .icon-arrow {
                display: none;
            }
        }
    }
}


@media screen and (max-width: 992px) {
    .jury .container-xxl {

        .social-networks {
            position: absolute;
            top: 200px;
        }
    }
}


@media screen and (max-width: 768px) {
    .jury .container-xxl {
        padding: 0 40px !important;

        .title-xl {
            font-weight: 400;
            font-size: 34px;
        }

        .social-networks {
            top: 155px;
        }

        .block-img img {
            height: 430px;
        }
    }
}

@media screen and (max-width: 576px) {
    .jury .container-xxl {
        padding: 0 20px !important;
    }
}

