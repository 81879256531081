.laureats h3 {
    font-size: 40px;
    padding-bottom: 25px;
    line-height: 34px;;
}

.laureats .block-laureat {
    margin: 60px 0;
    color: white;
}

.laureats .last {
    margin-bottom: 200px;
}

.laureats {
    .swiper {
       
        .swiper-wrapper {
            .swiper-slide {
                height: 350px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .buttons {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .button {
            display: flex;
            justify-content: center;
            padding: 20px;
            border-radius: 50%;
            border: 2px solid white;
            width: 45px;
            height: 45px;

            .swiper-button-next,
            .swiper-button-prev {
                position: static!important;
                color: white!important;

                &:after {
                    font-size: 20px;
                }
            }
        }

        .btn-left {
            margin-right: 30px;
        }
    }    
}


.laureats .credits {
    width: 100%;
    text-align: end;
    color: white;
    margin-top: 140px;
    margin-bottom: 20px;
    opacity: 50%;
    font-size: 12px;

    a {
        color: white;
        text-decoration: none;
    }
}


@media screen and (max-width: 500px) {
    .block-laureat h3 {
        font-size: 30px;
        line-height: 28px;;
    }
}